import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import Countly from 'countly-sdk-web'

Countly.init({
  app_key: "770c1caa07b8c2956204a737f2a93ce3cbdac68a",
  url: "https://infinite-57d25dbb05aa2.flex.countly.com/"
});

// track sessions automatically
Countly.track_sessions();
// track pageviews automatically
Countly.track_pageview();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals(sendToVercelAnalytics);
