import './App.css';
import { Analytics } from '@vercel/analytics/react'

function App() {
  return (
      <div className="App" >
        <header className="App-header">
          <h1>Infinite Green</h1>
          <p>info@infinitegreen.fi</p>
        </header>
        <Analytics />
      </div>

  );
}

export default App;
